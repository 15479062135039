import React, { useMemo } from 'react';

import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

export default function SheepGameNavbar() {
  const currentPlayer = useMemo(() => document.querySelector('[name=player-id]')?.content);
  const playerPhoto = useMemo(() => document.querySelector('[name=player-photo]')?.content);

  return (
    <Navbar bg="primary" variant="dark" expand="md" className="em-navbar" fixed="top">
      <Container>
        <Navbar.Brand href="/">Sheep Game</Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse>
          <Nav className="me-auto" />
          {currentPlayer && (
            <>
              {playerPhoto && (
                <Image fluid roundedCircle src={playerPhoto} className="sheep-game_player-photo" />
              )}
              <Button variant="outline-dark" href="/sign_out">
                Sign Out
              </Button>
            </>
          )}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
